// Main Navigation
const productMenu = document.getElementById("product_menu");
const companyMenu = document.getElementById("company_menu");
const recruitMenu = document.getElementById("recruit_menu");
const productMenuContainer = document.getElementById("product_menu_container");
const companyMenuContainer = document.getElementById("company_menu_container");
const recruitMenuContainer = document.getElementById("recruit_menu_container");

const menus = [productMenu, companyMenu, recruitMenu];
const containers = [
    productMenuContainer,
    companyMenuContainer,
    recruitMenuContainer,
];
function handle(event) {
    event.preventDefault();
}
// Hover Menus
for (let i = 0; i < menus.length; i++) {
    menus[i] &&
        menus[i].addEventListener("mouseover", () => {
            containers[i].classList.add("flex");
            document.addEventListener("mousewheel", handle, { passive: false });
            containers[i].addEventListener("mouseover", () => {
                containers[i].classList.add("flex");
                document.addEventListener("mousewheel", handle, {
                    passive: false,
                });
            });
        });
    menus[i] &&
        menus[i].addEventListener("mouseout", () => {
            containers[i].classList.remove("flex");
            document.removeEventListener("mousewheel", handle, {
                passive: false,
            });
            containers[i].addEventListener("mouseout", () => {
                containers[i].classList.remove("flex");
                document.removeEventListener("mousewheel", handle, {
                    passive: false,
                });
            });
        });
}

// Sub Navigation Toggle
const subNavIconArrow = document.querySelector(".subNavigation_icon-arrow");
if (subNavIconArrow) {
    $(".subNavigation_body").hide();

    $(".subNavigation_icon").on("click", function() {

        $(this).next(".subNavigation_body").slideToggle({
            duration: 250,
        });

        $("body").toggleClass("overlay");
        $(".subNavigation_icon-arrow").toggleClass("!rotate-[180deg]");
    });
}

const subNavItems = document.getElementsByClassName("subNavigation_item");
if (subNavItems) {
    $(".subNavigation_item").on("click", function() {
        $(this).next(".subNavigation_body").slideToggle({
            duration: 250,
        });
        $("body").toggleClass("overlay");
        $(".subNavigation_icon-arrow").toggleClass("!rotate-[180deg]");
    });
}
