import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/regular";

import "./js/bootstrap";
import "./css/globals.css";
import "./css/styles.css";
import "./css/button.css";
import "./css/hero.css";
import "./css/drawer.css";
import "./css/typography.css";
import "./css/layout.css";
import "./css/swiper.css";
import "./css/page.css";
import "./css/editor.css";
import "./css/form.css";

import "./js/viewport";
import "./js/swiper";
import "./js/navigation";
import "./js/drawer";
import "./js/hero";
import "./js/page";
import "./js/form";
