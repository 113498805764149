const toTop = document.getElementById("to_top");
const heroWrap = document.getElementById("hero_wrap");

// Hover effect on top page
$(".column_box").on("mouseover", function () {
    $(this).parent().next("li").find(".column_img").addClass("scale-105");
    $(this).children(".column_button").addClass("bg-hoverBlue text-text");
});

$(".column_box").on("mouseout", function () {
    $(this).parent().next("li").find(".column_img").removeClass("scale-105");
    $(this).children(".column_button").removeClass("bg-hoverBlue text-text");
});

$(".card_column .img a").on("mouseover", function () {
    $(this).find(".column_img").addClass("scale-105");
    $(this)
        .parent()
        .prev("li")
        .find(".column_button")
        .addClass("bg-hoverBlue text-text");
});

$(".card_column .img a").on("mouseout", function () {
    $(this).find(".column_img").removeClass("scale-105");
    $(this)
        .parent()
        .prev("li")
        .find(".column_button")
        .removeClass("bg-hoverBlue text-text");
});

$(".staff_box").on("mouseover", function () {
    $(this).find(".button_secondary").addClass("bg-hoverBlue");
    $(this).find(".staff_img").addClass("scale-105");
});

$(".staff_box").on("mouseout", function () {
    $(this).find(".button_secondary").removeClass("bg-hoverBlue");
    $(this).find(".staff_img").removeClass("scale-105");
});


// To Top Button
toTop.addEventListener("click", () => {
    heroWrap.scrollIntoView({
        behavior: "smooth",
    });
});

// Handle FloatButton Opacity
const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0,
};

const callback = (entries, observer) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            toTop.style.opacity = 0;
        } else {
            toTop.style.opacity = 100;
        }
    });
};

const observer = new IntersectionObserver(callback, options);
observer.observe(heroWrap);
