const navigation = document.getElementById("navigation");
const drawerWrap = document.getElementById("drawer");
const heroWrap = document.getElementById("hero_wrap");

const jadgeIsPc = () => navigation.clientHeight > 0;

// Add margin on hero top
const addMarginTop = () => {
    const isPc = jadgeIsPc();
    if (isPc) {
        heroWrap.style.marginTop = `${navigation.clientHeight}px`;
    }

    if (!isPc) {
        heroWrap.style.marginTop = `${drawerWrap.clientHeight}px`;
    }
};

addMarginTop();
addEventListener("resize", addMarginTop, false);

// Reduce hero toggle
// Sub navigation sticky
const heroReduce = document.getElementById("hero_reduce");
const subNavigation = document.getElementById("sub_navigation");
const heroProductPc = document.getElementById("hero_product_pc");
const heroProductSp = document.getElementById("hero_product_sp");
const swiperHeroProduct = document.getElementById("swiper_heroProduct");
const heroReduceHeight = heroReduce && heroReduce.clientHeight;
const heroWrapHeight = heroWrap.clientHeight;
let navigationHeight = navigation.clientHeight;
const heroProductHeight = heroProductPc && heroProductPc.clientHeight;

const options = {
    root: null,
    rootMargin: `-${navigationHeight + heroReduceHeight}px`,
    threshold: 0,
};

const isPc = jadgeIsPc();
if (isPc) {
    heroProductPc ? (heroProductPc.style.top = `${navigationHeight}px`) : null;
    heroProductSp
        ? (heroProductSp.style.marginTop = `${navigationHeight}px`)
        : null;

    swiperHeroProduct
        ? (swiperHeroProduct.style.marginTop = `${
              navigationHeight + heroProductHeight
          }px`)
        : null;
}
if (!isPc) {
    heroProductPc
        ? (heroProductPc.style.top = `${drawerWrap.clientHeight}px`)
        : null;
    heroProductSp
        ? (heroProductSp.style.marginTop = `${drawerWrap.clientHeight}px`)
        : null;

    // swiperHeroProduct
    //     ? (swiperHeroProduct.style.marginTop = `${
    //           drawerWrap.clientHeight + heroProductHeight
    //       }px`)
    //     : null;
}

addEventListener("resize", () => {
    navigationHeight = navigation.clientHeight;
    const heroProductHeight = heroProductPc && heroProductPc.clientHeight;

    const isPc = jadgeIsPc();
    if (isPc) {
        heroProductPc
            ? (heroProductPc.style.top = `${navigationHeight}px`)
            : null;
        heroProductSp
            ? (heroProductSp.style.marginTop = `${navigationHeight}px`)
            : null;

        swiperHeroProduct
            ? (swiperHeroProduct.style.marginTop = `${
                  navigationHeight + heroProductHeight
              }px`)
            : null;
    }
    if (!isPc) {
        heroProductPc
            ? (heroProductPc.style.top = `${drawerWrap.clientHeight}px`)
            : null;
        heroProductSp
            ? (heroProductSp.style.marginTop = `${drawerWrap.clientHeight}px`)
            : null;
    }
});

const callback = (entries, observer) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            heroReduce
                ? (heroReduce.style.transform = "translateY(-300px)")
                : null;
        } else {
            if (subNavigation) {
                const subNavigationOffset = navigationHeight + heroReduceHeight;
                subNavigation.style.top = `${subNavigationOffset}px`;
            }

            const isPc = jadgeIsPc();
            if (isPc) {
                heroReduce
                    ? (heroReduce.style.transform = `translateY(${navigationHeight}px)`)
                    : null;
            }

            if (!isPc) {
                heroReduce
                    ? (heroReduce.style.transform = `translateY(${drawerWrap.clientHeight}px)`)
                    : null;
            }
        }
    });
};

const observer = new IntersectionObserver(callback, options);
observer.observe(heroWrap);
