import Swiper, { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import "swiper/css/bundle";

const homeProduct = new Swiper(".swiper_homeProduct", {
    modules: [Navigation, Autoplay],
    speed: 500,
    slidesPerView: "auto",
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    loop: true,
    grabCursor: true,
    autoplay: {
        delay: 3000,
        disableOnInteraction: false,
    },
    breakpoints: {
        // when window width is >= 320px
        320: {
            slidesPerView: 1,
            spaceBetween: 8,
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 32,
        },
        1280: {
            slidesPerView: 2.5,
            spaceBetween: 16,
        },
        1535: {
            slidesPerView: 3,
            spaceBetween: 32,
        },
        // 1200: {
        //     slidesPerView: 4.2,
        //     spaceBetween: 50,
        // },
        // 1440: {
        //     slidesPerView: 4.8,
        //     spaceBetween: 50,
        // },
    },
});

const recruitSwiper = new Swiper(".swiper_recruit", {
    modules: [Autoplay, EffectFade],
    effect: "fade",
    fadeEffect: {
        crossFade: true,
    },
    speed: 3000,
    slidesPerView: 1,
    loop: true,
    autoplay: {
        delay: 3000,
        disableOnInteraction: false,
    },
});

const staffModal = document.getElementById("staffModal");
staffModal &&
    staffModal.addEventListener("hide.bs.modal", function (event) {
        $(".swiper-slide").scrollTop(0);
    });

$(".staff_box").on("click", function () {
    const recruitStaffSwiper = new Swiper(".swiper_recruit_staff", {
        modules: [Navigation, EffectFade],
        speed: 500,
        effect: "fade",
        fadeEffect: {
            crossFade: true,
        },
        slidesPerView: 1,
        grabCursor: true,
        initialSlide: $(this).data("modal_number") - 1,
        loop: true,

        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        on: {
            slideChange: function () {
                $(".swiper-slide").scrollTop(0);
            },
        },
    });
});
