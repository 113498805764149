const drawerButton = document.getElementById("drawer_button");
const drawerBody = document.getElementById("drawer_body");

$(drawerButton).on("click", function () {
    $(drawerBody).toggleClass("show");
    $(drawerButton).toggleClass("close");
});

const toggleIcon = () => {
    if ($(drawerButton).hasClass("close")) {
        drawerButton.innerHTML =
            '<i class="fa-solid fa-xmark text-white text-xl px-[2.5px]"></i>';
    } else {
        drawerButton.innerHTML =
            '<i class="fa-solid fa-bars text-white text-xl"></i>';
    }
};

$(".drawer_child").on("click", function () {
    $(drawerBody).removeClass("show");
    $(drawerButton).removeClass("close");
    toggleIcon();
});

$(drawerButton).on("click", function () {
    toggleIcon();
});

$(".drawer_accordion").children("ul").hide();

$(".drawer_accordion").on("click", function () {
    $(this).find("ul").slideToggle("fast");
    if ($(this).find("svg").hasClass("fa-minus")) {
        $(this).find("svg").removeClass("fa-minus");
        $(this).find("svg").addClass("fa-plus");
    } else {
        $(this).find("svg").addClass("fa-minus");
        $(this).find("svg").removeClass("fa-plus");
    }
});
