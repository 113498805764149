$('[name="subject"]:radio').change(function() {
  const result = $("input[name='subject']:checked").val();
  if (result === 'プロダクト事業について') {
    $('.form_body .forProduct').css('display', 'table-row');
  } else {
    $('.form_body .forProduct').css('display', 'none');
  }
}).trigger('change');

const area = ($('.mw_wp_form_confirm .subject').html());
if (area) {
  if (area.indexOf('プロダクト事業について') == -1) {
    $('.forProduct').css('display', 'none');
  }
}


$('[name="howto[data][]"]:checkbox').change(function() {
  const checkedItems = $("input[name='howto[data][]']:checked");
  const isChecked = checkedItems.toArray().some(item => $(item).val() === 'その他');

  if (isChecked) {
    $('.form_body .forOther').css('display', 'block');
  } else {
    $('.form_body .forOther').css('display', 'none');
  }
}).trigger('change');

const checkedItemsOnLoad = $("input[name='howto[data][]']:checked");
const isOtherIncludedOnLoad = checkedItemsOnLoad.toArray().some(item => $(item).val() === 'その他');
if (!isOtherIncludedOnLoad) {
  $('.forOther').css('display', 'none');
}

const other = ($('.mw_wp_form_confirm .howto').html());
if (area) {
  if (area.indexOf('その他') == -1) {
    $('.forOther').css('display', 'none');
  }
}
